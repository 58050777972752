import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// import app components
import { Banner, Edges, Headline, FlexibleContent, Spacer } from "components"

import { colors } from "theme"

export default function home(props) {
  const {
    data: {
      wpPage: {
        title,
        uri,
        acf: { footerBanner, headerBanner, modules }
      }
    }
  } = props

  const showIntro = props.location.pathname === "/" ? false : true

  return (
    <>
      {!!headerBanner.enableBanner && showIntro && (
        <>
          <StyledBanner image={headerBanner.bannerImage} />
        </>
      )}
      {showIntro && (
        <Edges size="md">
          <Spacer mt={50} />
          <Headline variant="h1" children={title} />
        </Edges>
      )}
      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            title,
            uri
          }}
        />
      )}
      {footerBanner.enableBanner && (
        <>
          <Edges size="lg">
            <PageOutroDesign />
          </Edges>
          <StyledFooterBanner image={footerBanner.bannerImage} />
        </>
      )}
    </>
  )
}

const StyledBanner = styled(Banner)`
  background-color: ${colors.secondary};
  height: 160px;
  width: 100%;
`

const PageOutroDesign = styled.div`
  height: 0px;
  margin-top: 120px;
  overflow: visible;
  position: relative;

  &:before {
    background: ${colors.alt2};
    border-radius: 50%;
    content: "";
    display: block;
    height: 175px;
    left: 0px;
    top: -80px;
    position: absolute;
    width: 175px;
  }

  &:after {
    background: ${colors.alt3};
    border-radius: 50%;
    content: "";
    display: block;
    height: 65px;
    left: 174px;
    top: -32px;
    position: absolute;
    width: 65px;
  }

  @media (min-width: 768px) {
    &:before {
      height: 205px;
      left: 0px;
      top: -102px;
      width: 205px;
    }

    &:after {
      height: 85px;
      left: 205px;
      top: -42px;
      width: 85px;
    }
  }
`

const StyledFooterBanner = styled(Banner)`
  background-color: ${colors.secondary};
  height: 160px;
  position: relative;
  width: 100%;
`

export const CollectionQuery = graphql`
  query DefaultTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acf {
        footerBanner {
          enableBanner
          bannerImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        headerBanner {
          enableBanner
          bannerImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        modules {
          ... on WpPage_Acf_Modules_Clients {
            __typename
            line1
            line2
            customOrSequentialColourOverlay
            clients {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 230
                      height: 230
                      quality: 100
                      layout: FIXED
                    )
                  }
                }
              }
              text
              name
              title
              colourOverlay
            }
          }
          ... on WpPage_Acf_Modules_CallToAction {
            __typename
            button {
              type
              link {
                url
                title
              }
            }
            content
            icon
          }
          ... on WpPage_Acf_Modules_Content {
            __typename
            margin
            content
            width
          }
          ... on WpPage_Acf_Modules_MultiColumnList {
            __typename
            columns
            twoColumnList {
              columnStyle
              column2
              column1
            }
            threeColumnList {
              columnStyle
              column3
              column2
              column1
            }
            fourColumnList {
              columnStyle
              column4
              column3
              column2
              column1
            }
          }
          ... on WpPage_Acf_Modules_NewsFeed {
            __typename
            category {
              name
            }
          }
          ... on WpPage_Acf_Modules_PageIntro {
            __typename
            content
            subHeading
            subheadingType
            orientation
          }
          ... on WpPage_Acf_Modules_TeamMembers {
            __typename
            teamMember {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 190, quality: 100, layout: FIXED)
                  }
                }
              }
              nameAndTitle
              text
            }
          }
          ... on WpPage_Acf_Modules_TextImage {
            __typename
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
                }
              }
            }
            text
          }
        }
      }
    }
  }
`
